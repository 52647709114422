import {
  Alert,
  Backdrop,
  Box,
  keyframes,
  Snackbar,
  Typography,
} from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

import IconSensor from '../images/icon_sensor.svg';

const Spinner: React.FC<{ debug?: boolean }> = ({ debug, children }) => {
  const waitingFor = useSpinner(useCallback((state) => state.waitingFor, []));
  const showSpinner = useMemo(() => waitingFor.length > 0, [waitingFor.length]);

  const skRotate = keyframes`
    50% { transform: rotate(720deg) scale(1.5); }
  `;

  return (
    <>
      <Backdrop
        sx={{
          transition: 'opacity 3s',
          backgroundColor: ({ palette }) => `${palette.primary.light}`,
          opacity: showSpinner ? '0.8 !important' : '0 !important',
          zIndex: ({ zIndex }) => zIndex.drawer + 1,
        }}
        open={showSpinner}
      >
        <Box
          sx={{
            backgroundImage: `url("${IconSensor}")`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            display: 'inline-block',
            width: '200px',
            height: '200px',
            position: 'relative',
            textAlign: 'center',
            animation: `${skRotate} 1.9s infinite`,
          }}
        />
      </Backdrop>
      {children}
      {debug && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={waitingFor.length > 0}
        >
          <Alert severity="info">
            <Typography variant="caption">Waiting for:</Typography>
            {waitingFor.map((name, i) => (
              <Typography key={`${i}-${name}`}>&bull; {name}</Typography>
            ))}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

interface SpinnerState {
  waitingFor: string[];
  wrap: <T>(name: string, promise: Promise<T>) => Promise<T>;
  add: (name: string) => void;
  remove: (name: string) => void;
  clear: () => void;
}

const useSpinner = create<SpinnerState>(
  devtools(
    (set) => ({
      waitingFor: [],
      wrap: (name, promise) => {
        set((state) => ({ waitingFor: [...state.waitingFor, name] }));
        return promise.finally(() =>
          set((state) => ({
            waitingFor: state.waitingFor.filter((w) => w !== name),
          })),
        );
      },
      add: (name) =>
        set((state) => ({ waitingFor: [...state.waitingFor, name] })),
      remove: (name) =>
        set((state) => ({
          waitingFor: state.waitingFor.filter((w) => w !== name),
        })),
      clear: () => set({ waitingFor: [] }),
    }),
    { name: 'spinner' },
  ),
);

export { useSpinner };
export default Spinner;
