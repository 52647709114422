import { Replay } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import SenseViewLogo from '../images/logo_senseview.svg';

export interface ConnectionErrorProps {
  onRetry: () => void;
}

export const ConnectionError: React.FC<ConnectionErrorProps> = ({
  onRetry,
}) => {
  return (
    <Stack
      height="100vh"
      width="100vw"
      display="flex"
      justifyContent="center"
      alignItems="center"
      spacing={4}
    >
      <Box
        sx={{
          width: '100px',
          height: '100px',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url("${SenseViewLogo}")`,
        }}
      />
      <Typography variant="subtitle1">
        <FormattedMessage
          id="connection_error.connection_error"
          defaultMessage="Sorry, there was a problem connecting to SenseView"
        />
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{ textTransform: 'none' }}
        startIcon={<Replay />}
        onClick={onRetry}
      >
        <FormattedMessage
          id="connection_error.refresh_to_try_again"
          defaultMessage="Refresh to try again"
        />
      </Button>
    </Stack>
  );
};

export default ConnectionError;
