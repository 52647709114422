// import ErrorBoundary from '@allbin/error-reporting';
import './App.css';

import { Settings } from 'luxon';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import Spinner from './components/Spinner';
import ConfigProvider from './ConfigProvider';
import Router from './Router';
import SentryProvider from './SentryProvider';
import messages_swedish from './translations/locales/sv-SE.json';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false },
  },
});

const messages: Record<string, any> = {
  'en-US': {},
  'sv-SE': messages_swedish,
};

// Can be used for testing purposes
// const ErrThrower: FC = () => {
//   useEffect(() => {
//     setTimeout(() => {
//       throw new Error('test failure, please ignore');
//     }, 5000);
//   }, []);
//   return <div />;
// };

const App: FC = () => {
  const [locale, setLocale] = useState(() => {
    console.log('initializing locale');
    const storedLocale = localStorage.getItem('locale');
    if (!messages[storedLocale as string]) {
      return 'en-US';
    }

    return storedLocale as string;
  });
  const [localeConfigured, setLocaleConfigured] = useState(false);

  useEffect(() => {
    // Remap locale for luxon
    let l = locale;
    switch (locale) {
      case 'sv-SE':
        l = 'sv';
        break;
      case 'en-US':
        l = 'en';
        break;
    }

    if (Settings.defaultLocale !== l) {
      Settings.defaultLocale = l;
      if (!localeConfigured) {
        setLocaleConfigured(true);
      }
    }
  }, [locale, localeConfigured]);

  const handleOnLocale = useCallback((l: string) => {
    const currentLocale = localStorage.getItem('locale');
    if (currentLocale === null) {
      localStorage.setItem('locale', l);
      setLocale(l);
    }
  }, []);
  console.log('locale', locale);

  return (
    // <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <Spinner>
        <ConfigProvider>
          <SentryProvider>
            <IntlProvider
              locale={locale}
              key={locale}
              messages={messages[locale]}
              defaultLocale="en-US"
            >
              <Router locale={locale} onLocale={handleOnLocale} />
              <ReactQueryDevtools />
            </IntlProvider>
          </SentryProvider>
        </ConfigProvider>
      </Spinner>
    </QueryClientProvider>
    // </ErrorBoundary>
  );
};

export default App;
